@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base{
    @font-face {
        font-family: 'Everett-Medium';
        src: url('../public/fonts/Everett-Medium.otf') format('otf'), ;
        font-weight:900;
        font-display:swap;
        font-style:normal;
    }
    @font-face {
        font-family: 'Everett-Regular';
        src: url('../public/fonts/Everett-Regular.otf') format('otf'), ;
        font-weight:400;
        font-display:swap;
        font-style:normal;
    }
    
}