.customer-form {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    border: 2px solid #ff462d;
    box-shadow: 24;
    background: #fff;
}

.customer-form__header {
    display: flex;
    align-items: center;
}

.customer-form__icon {
    font-size: 50px;
    margin: 0 1rem;
}

.customer-form__body {
    margin: 1rem 0;
    background: #fff;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
}

.customer-form__input {
    width: 100% !important;
    margin: 0 0 1.5rem 0 !important;
}

.customer-form__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 2.5rem 1.5rem 2.5rem;
}